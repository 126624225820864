.invoice-container {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    padding: 10px;
    background-color: #fff;
    color: #000;
  
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #000;
        padding-bottom: 15px;
        margin-bottom: 20px;
      
        .title {
          font-size: 26px;
          font-weight: bold;
        }

        .right-content {
            display: flex;
            align-items: center;
            gap: 20px; // Space
        
          .company-details {
            text-align: right;
            font-size: 14px;
            line-height: 1.5;
            margin-right: 10px;
            p {
              margin: 3px 0;
            }
          }

          .logo {
            img {
            height: 80px;
            }
        }
        }
      }
  
    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
  
      &.invoice-details {
        th,
        td {
          text-align: left;
          padding: 5px 0px;
          border: none;
          font-size: 12px;
        }
  
        th {
          font-weight: bold;
          width: 20%;
        }
      }
  
      &.sale-items {
        width: 100%;
        border-collapse: collapse;
  
        th,
        td {
          padding: 5px 5px;
          text-align: left;
          font-size: 12px;
          border: 1px solid #ddd;
          word-wrap: break-word;
        }
  
        th {
          background-color: #eaeaea;
          font-weight: bold;
        }
      }
    }
  
    .sale-items-container {
      overflow-x: auto;
    }
  
    .totals {
      text-align: left;
      font-size: 12px;
  
      p {
        margin: 8px 0;
  
        strong {
          display: inline-block;
          width: 110px;
        }
      }
  
      .grand-total {
        font-size: 16px;
        font-weight: bold;
        margin-top: 14px;
      }
    }
  
    .notes {
      margin-top: 30px;
      padding: 10px;
      border: 1px solid #ddd;
      background-color: #f8f8f8;
      font-size: 13px;
      line-height: 1.6;
    }
  
    .stamp-sign {
      margin-top: 100px;
      display: flex;
      justify-content: flex-end;
      .line {
        border-top: 2px solid #000;
        width: 20%;
        text-align: center;
        padding-top: 10px;
        font-weight: bold;
      }
    }
  }
  
  @media print {
    body {
      margin: 0;
      background-color: #fff;
    }
  
    .invoice-container {
      box-shadow: none;
      padding: 0;
    }
  
    @page {
      size: A4;
      margin: 10mm;
    }
  }