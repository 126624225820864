//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base/layout";
@import "layout/layout";


.global-stamp-sign {
    margin-top: 100px;
    display: flex;
    justify-content: flex-end;
    .line {
      border-top: 2px solid #000;
      width: 20%;
      text-align: center;
      padding-top: 10px;
      font-weight: bold;
    }
  }